/**
 * [fnLotteryType 彩票类型对应名称]
 * @param  {[String]} Type [彩票类型]
 * @return {[String]}     [彩票类型对应的名称]
 */
export function fnLotteryType(Type) {
	let t_sVal = "";
	switch (Type) {
		case "JCZQ": 
			t_sVal = "竞彩足球"
			break;
		case "JCLQ": 
			t_sVal = "竞彩篮球"
			break;
		case "BJDC": 
			t_sVal = "北京单场"
			break;
	}
	return t_sVal;
}